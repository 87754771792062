import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_08283616 from 'nuxt_plugin_plugin_08283616' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_sentryserver_4465b420 from 'nuxt_plugin_sentryserver_4465b420' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_7813b468 from 'nuxt_plugin_sentryclient_7813b468' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_clipboard_7d9b80e8 from 'nuxt_plugin_clipboard_7d9b80e8' // Source: ./clipboard.js (mode: 'client')
import nuxt_plugin_toast_0b8ac24a from 'nuxt_plugin_toast_0b8ac24a' // Source: ./toast.js (mode: 'client')
import nuxt_plugin_axios_2e0a522c from 'nuxt_plugin_axios_2e0a522c' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_image_19114f27 from 'nuxt_plugin_image_19114f27' // Source: ./image.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_d55bcc58 from 'nuxt_plugin_cookieuniversalnuxt_d55bcc58' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_fontLoader_2bb7b3ea from 'nuxt_plugin_fontLoader_2bb7b3ea' // Source: ./fontLoader.js (mode: 'client')
import nuxt_plugin_axios_2228ef02 from 'nuxt_plugin_axios_2228ef02' // Source: ../plugins/axios (mode: 'all')
import nuxt_plugin_route_231494d5 from 'nuxt_plugin_route_231494d5' // Source: ../plugins/route (mode: 'all')
import nuxt_plugin_pusher_3c67cc3b from 'nuxt_plugin_pusher_3c67cc3b' // Source: ../plugins/pusher (mode: 'all')
import nuxt_plugin_i18n_926bd3dc from 'nuxt_plugin_i18n_926bd3dc' // Source: ../plugins/i18n (mode: 'all')
import nuxt_plugin_vueslidercomponent_c11eff28 from 'nuxt_plugin_vueslidercomponent_c11eff28' // Source: ../plugins/vue-slider-component (mode: 'all')
import nuxt_plugin_iubenda_45555fb0 from 'nuxt_plugin_iubenda_45555fb0' // Source: ../plugins/iubenda (mode: 'all')
import nuxt_plugin_vueselect_3ecdefd2 from 'nuxt_plugin_vueselect_3ecdefd2' // Source: ../plugins/vue-select (mode: 'all')
import nuxt_plugin_filters_2abc1387 from 'nuxt_plugin_filters_2abc1387' // Source: ../plugins/filters (mode: 'all')
import nuxt_plugin_priceFormatter_75a3bc75 from 'nuxt_plugin_priceFormatter_75a3bc75' // Source: ../plugins/priceFormatter (mode: 'all')
import nuxt_plugin_gtag_0a2cb1e4 from 'nuxt_plugin_gtag_0a2cb1e4' // Source: ../plugins/gtag.js (mode: 'client')
import nuxt_plugin_vCalendar_1d5b7340 from 'nuxt_plugin_vCalendar_1d5b7340' // Source: ../plugins/vCalendar (mode: 'client')
import nuxt_plugin_facebookpixels_c39431c0 from 'nuxt_plugin_facebookpixels_c39431c0' // Source: ../plugins/facebook-pixels (mode: 'client')
import nuxt_plugin_gtm_caeab2a8 from 'nuxt_plugin_gtm_caeab2a8' // Source: ../plugins/gtm (mode: 'client')
import nuxt_plugin_googlemaps_c54ce192 from 'nuxt_plugin_googlemaps_c54ce192' // Source: ../plugins/google-maps (mode: 'client')
import nuxt_plugin_baidumap_22abff36 from 'nuxt_plugin_baidumap_22abff36' // Source: ../plugins/baidu-map (mode: 'client')
import nuxt_plugin_vuexpersist_61a29aad from 'nuxt_plugin_vuexpersist_61a29aad' // Source: ../plugins/vuex-persist (mode: 'client')
import nuxt_plugin_vuejsmodal_1dce8cf8 from 'nuxt_plugin_vuejsmodal_1dce8cf8' // Source: ../plugins/vue-js-modal (mode: 'client')
import nuxt_plugin_vueaos_e5d2166c from 'nuxt_plugin_vueaos_e5d2166c' // Source: ../plugins/vue-aos (mode: 'client')
import nuxt_plugin_scrollactive_ba6e6edc from 'nuxt_plugin_scrollactive_ba6e6edc' // Source: ../plugins/scroll-active (mode: 'client')
import nuxt_plugin_vuecreditcardvalidation_2d68859d from 'nuxt_plugin_vuecreditcardvalidation_2d68859d' // Source: ../plugins/vue-credit-card-validation (mode: 'client')
import nuxt_plugin_vueanimexyz_5980ffc5 from 'nuxt_plugin_vueanimexyz_5980ffc5' // Source: ../plugins/vue-anime-xyz (mode: 'client')
import nuxt_plugin_vueinfinitescroll_66aa6026 from 'nuxt_plugin_vueinfinitescroll_66aa6026' // Source: ../plugins/vue-infinite-scroll (mode: 'client')
import nuxt_plugin_intercom_e6a48884 from 'nuxt_plugin_intercom_e6a48884' // Source: ../plugins/intercom (mode: 'client')
import nuxt_plugin_metricoolpixel_b32be03e from 'nuxt_plugin_metricoolpixel_b32be03e' // Source: ../plugins/metricool-pixel (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"Keeway","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"Keeway"}],"link":[{"rel":"icon","type":"image\u002Fico","href":"\u002Ffavicon.ico"},{"hid":"font-prefetch","rel":"dns-prefetch","href":"https:\u002F\u002Ffonts.gstatic.com\u002F"},{"hid":"font-preconnect","rel":"preconnect","href":"https:\u002F\u002Ffonts.gstatic.com\u002F","crossorigin":""},{"hid":"font-preload","rel":"preload","as":"style","href":"https:\u002F\u002Ffonts.googleapis.com\u002Fcss2?family=Saira:ital,wght@0,100..900;1,100..900&display=block"}],"style":[],"script":[],"noscript":[{"hid":"font-noscript","innerHTML":"\u003Clink rel=\"stylesheet\" href=\"https:\u002F\u002Ffonts.googleapis.com\u002Fcss2?family=Saira:ital,wght@0,100..900;1,100..900&display=block\"\u003E"}],"__dangerouslyDisableSanitizersByTagID":{"font-noscript":["innerHTML"]}},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_08283616 === 'function') {
    await nuxt_plugin_plugin_08283616(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_sentryserver_4465b420 === 'function') {
    await nuxt_plugin_sentryserver_4465b420(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_7813b468 === 'function') {
    await nuxt_plugin_sentryclient_7813b468(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_clipboard_7d9b80e8 === 'function') {
    await nuxt_plugin_clipboard_7d9b80e8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_toast_0b8ac24a === 'function') {
    await nuxt_plugin_toast_0b8ac24a(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_2e0a522c === 'function') {
    await nuxt_plugin_axios_2e0a522c(app.context, inject)
  }

  if (typeof nuxt_plugin_image_19114f27 === 'function') {
    await nuxt_plugin_image_19114f27(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_d55bcc58 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_d55bcc58(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_fontLoader_2bb7b3ea === 'function') {
    await nuxt_plugin_fontLoader_2bb7b3ea(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_2228ef02 === 'function') {
    await nuxt_plugin_axios_2228ef02(app.context, inject)
  }

  if (typeof nuxt_plugin_route_231494d5 === 'function') {
    await nuxt_plugin_route_231494d5(app.context, inject)
  }

  if (typeof nuxt_plugin_pusher_3c67cc3b === 'function') {
    await nuxt_plugin_pusher_3c67cc3b(app.context, inject)
  }

  if (typeof nuxt_plugin_i18n_926bd3dc === 'function') {
    await nuxt_plugin_i18n_926bd3dc(app.context, inject)
  }

  if (typeof nuxt_plugin_vueslidercomponent_c11eff28 === 'function') {
    await nuxt_plugin_vueslidercomponent_c11eff28(app.context, inject)
  }

  if (typeof nuxt_plugin_iubenda_45555fb0 === 'function') {
    await nuxt_plugin_iubenda_45555fb0(app.context, inject)
  }

  if (typeof nuxt_plugin_vueselect_3ecdefd2 === 'function') {
    await nuxt_plugin_vueselect_3ecdefd2(app.context, inject)
  }

  if (typeof nuxt_plugin_filters_2abc1387 === 'function') {
    await nuxt_plugin_filters_2abc1387(app.context, inject)
  }

  if (typeof nuxt_plugin_priceFormatter_75a3bc75 === 'function') {
    await nuxt_plugin_priceFormatter_75a3bc75(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_gtag_0a2cb1e4 === 'function') {
    await nuxt_plugin_gtag_0a2cb1e4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vCalendar_1d5b7340 === 'function') {
    await nuxt_plugin_vCalendar_1d5b7340(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_facebookpixels_c39431c0 === 'function') {
    await nuxt_plugin_facebookpixels_c39431c0(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_gtm_caeab2a8 === 'function') {
    await nuxt_plugin_gtm_caeab2a8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_googlemaps_c54ce192 === 'function') {
    await nuxt_plugin_googlemaps_c54ce192(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_baidumap_22abff36 === 'function') {
    await nuxt_plugin_baidumap_22abff36(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuexpersist_61a29aad === 'function') {
    await nuxt_plugin_vuexpersist_61a29aad(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuejsmodal_1dce8cf8 === 'function') {
    await nuxt_plugin_vuejsmodal_1dce8cf8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueaos_e5d2166c === 'function') {
    await nuxt_plugin_vueaos_e5d2166c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_scrollactive_ba6e6edc === 'function') {
    await nuxt_plugin_scrollactive_ba6e6edc(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuecreditcardvalidation_2d68859d === 'function') {
    await nuxt_plugin_vuecreditcardvalidation_2d68859d(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueanimexyz_5980ffc5 === 'function') {
    await nuxt_plugin_vueanimexyz_5980ffc5(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueinfinitescroll_66aa6026 === 'function') {
    await nuxt_plugin_vueinfinitescroll_66aa6026(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_intercom_e6a48884 === 'function') {
    await nuxt_plugin_intercom_e6a48884(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_metricoolpixel_b32be03e === 'function') {
    await nuxt_plugin_metricoolpixel_b32be03e(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    router.push(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
